import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import { AppState } from './types';
import { reducer } from './reducer';
import { rootEpic } from './epics';

export const epicMiddleware = createEpicMiddleware();

export const store = configureStore<AppState>({
    middleware: [epicMiddleware],
    reducer,
});

epicMiddleware.run(rootEpic);
