import React, { FC } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export interface CardProps {
    title: string | JSX.Element;
    buttonText?: string;
    isDisabled?: boolean;
    onClick: () => void;
}

export const Card: FC<CardProps> = ({ title, children, isDisabled = false, buttonText, onClick }) => (
    <div className="card__container">
        <h1>{title}</h1>
        <main>{children}</main>
        {buttonText && (
            <footer>
                <button className="button next-button" disabled={isDisabled} onClick={onClick}>
                    {buttonText}
                </button>
            </footer>
        )}
    </div>
);

Card.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
