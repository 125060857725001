import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';

export interface CheckboxProps {
    label: string;
    checked: boolean;
    id?: string;
    onChange: () => void;
}

export const Checkbox: FC<CheckboxProps> = ({ label, id, checked, onChange }) => {
    if (!id) {
        id = uuidv4();
    }

    return (
        <>
            <input type="checkbox" id={id} checked={checked} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
