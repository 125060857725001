export const getDistanceFromLatLonInKm = (aPoint: number[], bPoint: number[]): number => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(bPoint[0] - aPoint[0]); // deg2rad below
    const dLon = deg2rad(bPoint[1] - aPoint[1]);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(aPoint[0])) * Math.cos(deg2rad(bPoint[0])) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
};

const deg2rad = (deg: number): number => {
    return deg * (Math.PI / 180);
};
