import React, { FC, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect, Provider } from 'react-redux';
import { Placemark, YMaps, Map } from 'react-yandex-maps';
import { Pushover } from 'pushover-js';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { store } from './store';
import { Card, Checkbox, Rating, Spinner } from './components';
import { AppState } from './types';
import {
    CINEMA_COORDINATES,
    CIPHER_TEXT,
    DESTANCE_TO_DESTINATION,
    FLAT_COORDINATES,
    PUSHOVER_API_KEY,
    PUSHOVER_USER_ID,
    RESTAURANT_COORDINATES,
    TAXI_LINK,
} from './constants';
import {
    changeCheckedStatus,
    getDataFromSessionStorage,
    saveToSessionStorage,
    setActiveCard,
    setIsLoggedIn,
    setMapLoadStatus,
    setTaxiCallStatus,
    startWatchGeolocation,
    stopWatchGeolocation,
} from './reducer';
import { getDistanceFromLatLonInKm } from './utils';

import './App.scss';

import breakfast from './assets/breakfast.svg';
import cinema from './assets/cinema.svg';
import present from './assets/present.svg';
import relax from './assets/relax.svg';

const getCardsContent = (props: AppState & AppDispatchProps) => {
    const {
        activeCard,
        cards,
        taxiCallStatus,
        mapLoadStatus,
        isLoggedIn,
        onChangeCheckboxStatus,
        onTaxiCall,
        onLoadMap,
    } = props;

    if (!isLoggedIn) {
        return <input type="password" id="login-input" />;
    }

    switch (activeCard) {
        case 0:
            return (
                <>
                    <p className="without-margin">Привет &#128075;</p>
                    <p>
                        Для того, чтобы сделать этот день более особенным, я решил сделать рассказ сегодняшней агенды в
                        немного интерактивной форме &#128242;
                    </p>
                    <p className="without-margin">Давай начнем.</p>
                </>
            );
        case 1:
            return (
                <div className="list">
                    {cards[activeCard].data?.map(({ label, checked }, index) => (
                        <Checkbox
                            label={label}
                            checked={checked}
                            key={index}
                            onChange={() => onChangeCheckboxStatus(activeCard, index, !checked)}
                        />
                    ))}
                </div>
            );
        case 2:
            return (
                <>
                    <p>Давай доберемся до нашего места дислокации</p>
                    {!mapLoadStatus && <Spinner />}
                    <YMaps>
                        <Map
                            defaultState={{ center: FLAT_COORDINATES, zoom: 15 }}
                            width="100%"
                            onLoad={() => onLoadMap()}
                        >
                            <Placemark defaultGeometry={FLAT_COORDINATES} />
                        </Map>
                    </YMaps>
                    {taxiCallStatus ? (
                        <p className="important">Ожидайте сообщения со статусом кареты 😊</p>
                    ) : (
                        <button className="button taxi-button" onClick={onTaxiCall}>
                            Вызвать карету
                        </button>
                    )}
                    <p className="without-margin">
                        Следующий шаг будет доступен после прибытия на место
                        <i className="warning" />
                    </p>
                </>
            );
        case 3:
            return (
                <>
                    <p className="without-margin">Пора окунуться в атмосферу Парижа 60-ых в 4к</p>
                    <img src={cinema} className="content-image" alt="cinema" />
                    <p className="without-margin">
                        Следующий шаг будет доступен после прибытия на место
                        <i className="warning" />
                    </p>
                </>
            );
        case 4:
            return (
                <>
                    <p className="without-margin">А теперь время для подарков &#127881;</p>
                    <img src={present} className="content-image" alt="present" />
                    <p className="without-margin">
                        Следующий шаг будет доступен после прибытия на место
                        <i className="warning" />
                    </p>
                </>
            );
        case 5:
            return (
                <>
                    <p className="without-margin">
                        Наверное ты устала за сегодня. Как насчет массажа и spa-процедур? 💆‍♀️
                    </p>
                    <img src={relax} className="content-image" alt="relax" />
                    <p className="without-margin">
                        Следующий шаг будет доступен завтра утром
                        <i className="warning" />
                    </p>
                </>
            );
        case 6:
            return (
                <>
                    <p className="without-margin">Готова позавтракать?</p>
                    <img src={breakfast} className="content-image" alt="breakfast" />
                    <p className="without-margin">
                        Следующий шаг будет доступен после прибытия на место
                        <i className="warning" />
                    </p>
                </>
            );
        case 7:
            return (
                <>
                    <p>Понравился ли Вам наш сервис? Нам очень важно Ваше мнение</p>
                    <Rating />
                </>
            );
    }
};

const isButtonDisabled = ({ activeCard, cards, currentPosition, isLoggedIn }: AppState) => {
    if (!isLoggedIn) {
        return false;
    }

    switch (activeCard) {
        case 0:
        case 7:
            return false;
        case 1:
            return cards[activeCard].data?.some(item => !item.checked);
        case 2:
        case 4:
            if (currentPosition) {
                return !(
                    getDistanceFromLatLonInKm(FLAT_COORDINATES, [currentPosition.latitude, currentPosition.longitude]) <
                    DESTANCE_TO_DESTINATION
                );
            }

            return true;
        case 3:
            if (currentPosition) {
                return !(
                    getDistanceFromLatLonInKm(CINEMA_COORDINATES, [
                        currentPosition.latitude,
                        currentPosition.longitude,
                    ]) < DESTANCE_TO_DESTINATION
                );
            }
            return true;
        case 5:
            if (currentPosition) {
                return Date.now() < 1628485200000;
            }
            return true;
        case 6:
            if (currentPosition) {
                return !(
                    getDistanceFromLatLonInKm(RESTAURANT_COORDINATES, [
                        currentPosition.latitude,
                        currentPosition.longitude,
                    ]) < DESTANCE_TO_DESTINATION
                );
            }
            return true;
    }
};

function mapStateToProps(state: AppState): AppState {
    return { ...state };
}

interface AppDispatchProps {
    onInit: () => void;
    onLastCard: () => void;
    onTaxiCall: () => void;
    onLoadMap: () => void;
    onLogin: () => void;
    onSetActiveCard: (activeCard: number) => void;
    onChangeCheckboxStatus: (cardIndex: number, chackboxIndex: number, newStatus: boolean) => void;
}

function mapDispatchToProps(dispatch: any): AppDispatchProps {
    return {
        onInit: (): void => {
            dispatch(getDataFromSessionStorage());
            dispatch(startWatchGeolocation());
        },
        onLastCard: (): void => {
            dispatch(stopWatchGeolocation());
        },
        onTaxiCall: (): void => {
            const pushover = new Pushover(PUSHOVER_USER_ID, PUSHOVER_API_KEY);

            // Simple notification (without personalization)
            pushover
                .send('Вызов такси', TAXI_LINK)
                .then(() => {
                    dispatch(setTaxiCallStatus(true));
                    dispatch(saveToSessionStorage());
                })
                .catch(console.error);
        },
        onLoadMap: (): void => {
            dispatch(setMapLoadStatus(true));
        },
        onLogin: (): void => {
            const input = document.querySelector('#login-input');
            const value = (input as any)?.value || '';

            if (value) {
                const neededValue = AES.decrypt(CIPHER_TEXT, value).toString(encUTF8);

                if (neededValue === value) {
                    dispatch(setIsLoggedIn(true));
                    dispatch(saveToSessionStorage());
                } else {
                    toast.error('Вы не Настя, а значит Вам сюда не надо');
                }
            }
        },
        onSetActiveCard: (activeCard: number): void => {
            dispatch(setActiveCard(activeCard));
            dispatch(saveToSessionStorage());
        },
        onChangeCheckboxStatus: (cardIndex: number, checkboxIndex: number, newStatus: boolean): void => {
            dispatch(changeCheckedStatus({ cardIndex, checkboxIndex, newStatus }));
            dispatch(saveToSessionStorage());
        },
    };
}

const App: FC<AppState & AppDispatchProps> = (props: AppState & AppDispatchProps) => {
    const {
        activeCard,
        cards,
        currentPosition,
        geolocationWatchId,
        taxiCallStatus,
        mapLoadStatus,
        isLoggedIn,
        onInit,
        onSetActiveCard,
        onLastCard,
        onLogin,
    } = props;
    const { title, button } = cards[activeCard];
    const content = getCardsContent(props);
    const isDisabled = isButtonDisabled({
        cards,
        activeCard,
        currentPosition,
        geolocationWatchId,
        taxiCallStatus,
        mapLoadStatus,
        isLoggedIn,
    });

    useEffect(() => {
        onInit();
    }, []);

    useEffect(() => {
        if (activeCard === cards.length - 1) {
            onLastCard();
        }
    }, [activeCard]);

    const cardTitle = isLoggedIn ? (
        title
    ) : (
        <span>
            {'Введите дату рождения в формате '}
            <i style={{ textDecoration: 'underline', fontStyle: 'normal' }}>DDMMYYYY</i>
        </span>
    );
    const cardButtonText = isLoggedIn ? button : 'Авторизоваться';
    const onClick = isLoggedIn ? () => onSetActiveCard(activeCard + 1) : onLogin;

    return (
        <div className={`App${isLoggedIn ? ' loggedIn' : ' notLoggedIn'}`}>
            <Card title={cardTitle} buttonText={cardButtonText} isDisabled={isDisabled} onClick={onClick}>
                {content}
            </Card>
            {!isLoggedIn && (
                <ToastContainer
                    hideProgressBar
                    draggable
                    position="top-center"
                    autoClose={3000}
                    closeOnClick={false}
                    pauseOnFocusLoss={false}
                    pauseOnHover={false}
                    closeButton={false}
                    transition={Slide}
                    style={{ textAlign: 'center' }}
                />
            )}
        </div>
    );
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

const AppContainer: FC = () => {
    return (
        <Provider store={store}>
            <ConnectedApp />
        </Provider>
    );
};

const HotAppContainer = hot(AppContainer);

export default HotAppContainer;
