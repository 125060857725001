import { combineReducers, createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Card, CheckboxData, CheckboxPayload } from './types';

const cards: Card[] = [
    { title: 'Приветствие', button: 'Поехали!' },
    {
        title: 'Что взять с собой',
        button: 'Доне',
        data: [
            { label: 'Необходимые вещи для сна (с меня пенка, тоник, ватные диски)', checked: false },
            { label: 'Хорошее настроение', checked: false },
        ],
    },
    { title: 'Отправляемся в путь', button: 'Я на месте' },
    { title: 'Отправляемся в приключение', button: 'Так-с, что там у нас еще?' },
    { title: 'Время дарить подарки', button: 'Что дальше?' },
    { title: 'Relax time', button: 'Что там на утро приготовлено?' },
    { title: 'Время покормить ребенка', button: 'Ребенок поел' },
    { title: 'Оцените наш сервис' },
];

export const setActiveCard = createAction<number>('setActiveCard');
export const setCards = createAction<Card[]>('setCards');
export const setCurrentPosition = createAction<GeolocationCoordinates | null>('setCurrentPosition');
export const setGeolocationWatchId = createAction<number | null>('setGeolocationWatchId');
export const setTaxiCallStatus = createAction<boolean>('setTaxiCallStatus');
export const setMapLoadStatus = createAction<boolean>('setMapLoadStatus');
export const setIsLoggedIn = createAction<boolean>('setIsLoggedIn');

export const changeCheckedStatus = createAction<CheckboxPayload>('changeCheckedStatus');

export const saveToSessionStorage = createAction<void>('saveToSessionStorage');
export const getDataFromSessionStorage = createAction<void>('getDataFromSessionStorage');

export const startWatchGeolocation = createAction<void>('startWatchGeolocation');
export const stopWatchGeolocation = createAction<void>('stopWatchGeolocation');

export const currentPositionReducer = createReducer<GeolocationCoordinates | null>(null, {
    [setCurrentPosition.type]: (
        _,
        action: PayloadAction<GeolocationCoordinates | null>,
    ): GeolocationCoordinates | null => {
        return action.payload;
    },
});

export const geolocationWatchIdReducer = createReducer<number | null>(null, {
    [setGeolocationWatchId.type]: (_, action: PayloadAction<number | null>): number | null => {
        return action.payload;
    },
});

export const taxiCallStatusReducer = createReducer<boolean>(false, {
    [setTaxiCallStatus.type]: (_, action: PayloadAction<boolean>): boolean => {
        return action.payload;
    },
});

export const mapLoadStatusReducer = createReducer<boolean>(false, {
    [setMapLoadStatus.type]: (_, action: PayloadAction<boolean>): boolean => {
        return action.payload;
    },
});

export const isLoggedInReducer = createReducer<boolean>(false, {
    [setIsLoggedIn.type]: (_, action: PayloadAction<boolean>): boolean => {
        return action.payload;
    },
});

export const cardsReducer = createReducer<Card[]>(cards, {
    [changeCheckedStatus.type]: (cards, action: PayloadAction<CheckboxPayload>): Card[] => {
        const copy = JSON.parse(JSON.stringify(cards)) as Card[];
        const { cardIndex, checkboxIndex, newStatus } = action.payload;
        (copy[cardIndex].data as unknown as CheckboxData[])[checkboxIndex].checked = newStatus;

        return copy;
    },
    [setCards.type]: (_, action: PayloadAction<Card[]>): Card[] => {
        return action.payload;
    },
});

export const activeCardReducer = createReducer<number>(0, {
    [setActiveCard.type]: (_, action: PayloadAction<number>): number => {
        return action.payload;
    },
});

export const reducer = combineReducers<AppState>({
    cards: cardsReducer,
    activeCard: activeCardReducer,
    currentPosition: currentPositionReducer,
    geolocationWatchId: geolocationWatchIdReducer,
    taxiCallStatus: taxiCallStatusReducer,
    mapLoadStatus: mapLoadStatusReducer,
    isLoggedIn: isLoggedInReducer,
});
