import { PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, StateObservable, ActionsObservable, ofType } from 'redux-observable';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SESSION_STORAGE_KEY } from './constants';
import {
    getDataFromSessionStorage,
    saveToSessionStorage,
    setActiveCard,
    setCards,
    setCurrentPosition,
    setGeolocationWatchId,
    setIsLoggedIn,
    setTaxiCallStatus,
    startWatchGeolocation,
    stopWatchGeolocation,
} from './reducer';
import { store } from './store';
import { AppState } from './types';

export const saveToSessionStorageEpic: Epic = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) =>
    action$.pipe(
        ofType(saveToSessionStorage.type),
        withLatestFrom(store$),
        tap(([_, state]) => sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state))),
        switchMap(() => []),
    );

export const getDataFromSessionStorageEpic: Epic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(getDataFromSessionStorage.type),
        switchMap(() => {
            const stringData = sessionStorage.getItem(SESSION_STORAGE_KEY);

            if (stringData) {
                const { activeCard, cards, taxiCallStatus, isLoggedIn }: AppState = JSON.parse(stringData);

                return [
                    setCards(cards),
                    setActiveCard(activeCard),
                    setTaxiCallStatus(taxiCallStatus),
                    setIsLoggedIn(isLoggedIn),
                ];
            }
            return [];
        }),
    );

export const startWatchGeolocationEpic: Epic = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) =>
    action$.pipe(
        ofType(startWatchGeolocation.type),
        withLatestFrom(store$),
        switchMap(() => {
            const id = window.setInterval(() => {
                navigator.geolocation.getCurrentPosition(
                    location => store.dispatch(setCurrentPosition(location.coords)),
                    error => console.log(error),
                    { enableHighAccuracy: true },
                );
            }, 10000);

            return [setGeolocationWatchId(id)];
        }),
    );

export const stopWatchGeolocationEpic: Epic = (action$: ActionsObservable<any>, store$: StateObservable<AppState>) =>
    action$.pipe(
        ofType(stopWatchGeolocation.type),
        switchMap(() =>
            store$.pipe(
                take(1),
                switchMap(({ geolocationWatchId }: AppState): PayloadAction<any>[] => {
                    if (geolocationWatchId) {
                        window.clearInterval(geolocationWatchId);

                        return [setGeolocationWatchId(null)];
                    }
                    return [];
                }),
            ),
        ),
    );

export const sessionStorageEpics: Epic[] = [saveToSessionStorageEpic, getDataFromSessionStorageEpic];
export const geolocationEpics: Epic[] = [startWatchGeolocationEpic, stopWatchGeolocationEpic];

export const rootEpic: Epic = combineEpics(...sessionStorageEpics, ...geolocationEpics);
